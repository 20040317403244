<template>
  <a-modal title="Create Group" :visible="visible" @cancel="close">
    <a-form layout="vertical">
      <a-form-item name="groupName" label="Group Name">
        <a-input
          v-model:value="state.groupName"
          type="text"
          autocomplete="off"
          placeholder="Enter group name"
        />
      </a-form-item>
      <a-form-item>
        <label for="select-group-create">Social Media Account</label>
        <p class="description mb-7">add at least one social media account</p>
        <div class="d-flex">
          <a-select
            id="select-group-create"
            class="sel-group-create"
            v-model:value="socialValue"
            size="large"
            show-search
            style="width: 100%"
            :default-active-first-option="false"
            :getPopupContainer="(trigger) => trigger.parentNode"
            :show-arrow="false"
            :filter-option="true"
            not-found-content="Not Found"
            :options="filterMediaDatas"
            option-filter-prop="label"
            option-label-prop="html"
            placeholder="Enter Username/Page/Channel/ID"
            @inputKeyDown="handleChange"
            @select="selectItem"
          >
            <template #option="{ label, source, id }">
              <SourceBox
                :source="{ source, name: label, id }"
                :show-id="true"
              ></SourceBox>
            </template>
          </a-select>
          <a-button class="ml-8 btn-add-group" type="primary" @click="addSocial"
            >Add</a-button
          >
        </div>
      </a-form-item>
    </a-form>
    <div v-for="socialMedia in state.selectedMedias" :key="socialMedia.value">
      <a-row justify="space-between" class="mt-20">
        <a-col>
          <SourceBox :source="socialMedia" :show-id="true"></SourceBox>
        </a-col>
        <a-col>
          <FeatherIcon
            type="trash-2"
            class="color-dim"
            @click="removeSocialMediaFromList(socialMedia.value)"
          ></FeatherIcon>
        </a-col>
      </a-row>
    </div>

    <template #footer>
      <div class="action-button-modal text-right">
        <a-button
          size="large"
          type="default"
          class="cancel-button mr-8"
          :outlined="true"
          @click="close"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          type="primary"
          class="create-button"
          :disabled="isDisableCreate"
          @click="createGroup"
          >Create</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import { computed, ref, toRefs, watch, reactive, h } from 'vue';
import SourceBox from './SourceBox.vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
import { useStore } from 'vuex';
import _ from 'lodash';
export default {
  components: { SourceBox },
  props: {
    visible: Boolean,
  },
  emits: ['close', 'success'],
  setup(props, context) {
    const { visible } = toRefs(props);
    const store = useStore();
    const state = reactive({
      groupName: '',
      selectedMedias: [],
    });

    const socialValue = ref(null);
    const selected = ref(null);

    const getHtml = (source) => {
      const sourceImg = {
        facebook: require('@/assets/images/source-icon/facebook.jpg'),
        twitter: require('@/assets/images/source-icon/svg/twitter.svg'),
        instagram: require('@/assets/images/source-icon/svg/instagram.svg'),
        youtube: require('@/assets/images/source-icon/svg/youtube.svg'),
        pantip: require('@/assets/images/source-icon/svg/pantip.svg'),
        website: require('@/assets/images/source-icon/svg/website.svg'),
      };

      let sourceImageInfo = sourceImg[source.source];

      return h('div', { class: 'source-box' }, [
        h('span', { class: ['source-tag', source.source] }, [
          h('span', { class: 'logo' }, [
            h(
              'span',
              {
                class: sourceImageInfo ? source.source : 'website',
              },
              [
                h('img', {
                  src: sourceImageInfo ? sourceImageInfo : sourceImg.website,
                }),
              ]
            ),
          ]),
        ]),
        h('span', { class: ['ml-2', 'font'] }, source.name),
        h('span', { class: ['ml-2', 'font'] }, `(${source.id})`),
      ]);
    };

    const resetFormData = () => {
      state.groupName = '';
      state.selectedMedias = [];

      selected.value = null;
      socialValue.value = null;
    };

    const createGroup = async () => {
      const data = {
        name: state.groupName,
        medias: state.selectedMedias.map((v) => {
          return {
            source: v.source,
            username: v.name,
            userId: v.value,
          };
        }),
      };

      if (data.medias.length === 0) return;
      try {
        let result = await api.addSocialMediaGroup(data);
        if (result.code === 200) {
          notification.success({
            message: 'Success',
            description: `Group ${data.name} added.`,
          });
          context.emit('success');
          context.emit('close');
        } else {
          notification.error({
            message: 'Error',
            description: `Group ${data.name} add failed.`,
          });
          context.emit('error');
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `Group ${data.name} add failed.`,
        });
        context.emit('error');
      }
    };

    const selectItem = (selectedId) => {
      let selectedItem = socialMediaDatas.value.filter(
        (v) => v.value === selectedId
      )[0];
      if (selectedItem) {
        selected.value = selectedItem;
      }
    };
    const addSocial = () => {
      if (selected.value) {
        state.selectedMedias.push(selected.value);
      }
      selected.value = null;
      socialValue.value = null;
    };

    const handleChange = _.debounce((e) => {
      store.dispatch('socialMediaGroup/loadSocialMediaAccounts', {
        username: e.target.value,
      });
    }, 100);

    const close = () => {
      context.emit('close');
    };
    const removeSocialMediaFromList = (value) => {
      state.selectedMedias = state.selectedMedias.filter(
        (v) => v.value !== value
      );
    };

    const socialMediaDatas = computed(() => {
      return store.state.socialMediaGroup.socialMediaAccounts.map((v) => {
        return { ...v, html: getHtml(v) };
      });
    });
    const filterMediaDatas = computed(() => {
      return socialMediaDatas.value.filter((v) => {
        for (let social of state.selectedMedias) {
          if (social.value == v.value) {
            return false;
          }
        }
        return true;
      });
    });

    const isDisableCreate = computed(() => {
      return !(state.selectedMedias.length && state.groupName);
    });

    watch(visible, resetFormData);

    return {
      state,
      socialValue,

      filterMediaDatas,
      isDisableCreate,

      getHtml,
      createGroup,
      addSocial,
      handleChange,
      close,
      removeSocialMediaFromList,
      resetFormData,
      selectItem,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep(.sel-group-create) {
  .font {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .source-tag {
    // border-radius: 18px;
    height: 16px;
    line-height: 14px;
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize;
    // padding: 2px 5px 2px 2px;
    white-space: nowrap;
    display: inline-block;
    .logo {
      height: 16px;
      width: 16px;
      border-radius: 16px;
      color: #fff;
      margin-right: 4px;
      line-height: 14px;
      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // background-color: #1bc5bd;
    }
    &.facebook {
      img {
        width: 16px;
        height: 16px;
      }
    }
    &.twitter {
      .logo {
        background-color: rgb(34, 164, 240);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.pantip {
      .logo {
        background-color: rgba(118, 116, 188, 1);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.youtube {
      .logo {
        background-color: #ff0000;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.instagram {
      .logo {
        background-color: rgba(228, 64, 95, 1);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.website {
      .logo {
        background-color: #060808;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .pantip img,
  .website img {
    width: 16px;
    height: 16px;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .source-box {
    display: flex;
    align-items: center;
    margin-top: 13px;
  }
}

.ml-8 {
  margin-left: 8px;
}

.mt-20 {
  margin-top: 20px;
}
.cancel-button {
  background: #f4f5f7;
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a5f7d;
}

.create-button {
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.create-button:disabled {
  opacity: 0.5;
}

.color-dim {
  color: #adb4d2;
  cursor: pointer;
}
.description {
  color: #9299b8;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.mb-7 {
  margin-bottom: 7px;
}

.btn-add-group {
  height: 48px;
}
</style>
<style lang="scss">
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
  height: 48px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 48px !important;
}

// .sel-group-create .ant-select-selection-item div {
//   margin-top: 13px;
// }

.ant-select-item-option-content div {
  display: flex;
  align-items: center;
}

#groupName {
  font-size: 16px;
}
</style>
